import styled from 'styled-components';

export const Font = styled.div`
  font-family: 'BankGothic';
  color:#fff;
  overflow:hidden;
`

export const FixedBackground = styled.div`
  position:fixed;
  background-image:url(${props => props.image});
  top:0px;
  left:0px;
  width:100%;
  height:100%;
  background-size: cover;
  background-position: center center;
  z-index:-1;
`

export const InstagramIcon = styled.img`
  width:29px;
  height:29px;
`

export const LogoRow = styled.div`
  margin-top:5vh;
`

export const MarginTop = styled.div`
  margin-top:${props => props.remValue}rem;
`

export const MarginBottom = styled.div`
  margin-bottom:${props => props.remValue}rem;
`

export const FontWeight = styled.div`
  font-weight:${props => props.fontWeight};
`

export const UnorderedList = styled.div`
  list-style-position:${props => props.listStylePosition};
`

export const FontSize = styled.div`
  font-size:${props => props.fontSize};
`

export const TextAlign = styled.div`
  text-align:${props => props.textAlign};
`

export const VisualStyleLink = styled.span`
  color: #fff;
  text-decoration: underline;

  &:hover {
    color:#fff;
    position:relative;
    top:1px;
    left:1px;
  }
`

export const SliderImage = styled.img`
  max-height:80vh;
`

export const Link = styled.a`
  color:#fff;
  text-decoration:underline;

  &:hover {
    color:#fff;
    position:relative;
    top:1px;
    left:1px;
  }
`