import { createGlobalStyle } from 'styled-components';

import BankGothicWoff from './bankgothic-lt-bt-light-webfont.woff';
import BankGothicEot from './bankgothic-lt-bt-light-webfont.eot';
import BankGothicTtf from './bankgothic-lt-bt-light-webfont.ttf';
import BankGothicSvg from './bankgothic-lt-bt-light-webfont.svg';

export default createGlobalStyle`
    @font-face {
        font-family: 'BankGothic';
        src: local('BankGothic'), local('BankGothic'),
        src: url(${BankGothicEot});
        src: url(${BankGothicEot + '?#iefix'}) format('eot'),
        url(${BankGothicWoff}) format('woff'),
        url(${BankGothicTtf}) format('truetype'),
        url(${BankGothicSvg + '#webfontqkeXzljY'}) format('svg');
        font-weight: normal;
        font-style: normal;
    }
`;