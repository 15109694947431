import React, { Component } from 'react';
import styled from 'styled-components';
import mailIcon from '../assets/images/mail.svg';

const MailImage = styled.img`
    width:20px;
`

const AddressButton = styled.button`
    border:0px;
    background:transparent;
    color:#fff;
    text-decoration:underline;

    &:hover {
        color:#fff;
        position:relative;
        top:1px;
        left:1px;
    }
`

class EmailAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleClick(event) {
        event.preventDefault();
        document.location.href = "mailto:" + this.props.emailAddress;
    }

    render() {
        let displayAddress = '';
        if(this.props.emailAddress !== undefined) {
            displayAddress = this.props.emailAddress.replace('@','<span> (at) </span>').replace('.','<span class="displayNone">Some random string which cannot be seen - hahaha</span>.');
        }
        return (
            <span>
                <MailImage src={mailIcon} alt="E-Mail" className="phone" /> <AddressButton className={this.props.classNames} onClick={this.handleClick.bind(this)}><span dangerouslySetInnerHTML={{__html: displayAddress}}></span></AddressButton>
            </span>
        );
    }
}

export default EmailAddress;