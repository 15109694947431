import React from 'react';
import styled from 'styled-components';

import arrowTop from '../assets/images/arrowTop.svg';

const Div = styled.div`
    position:fixed;
    z-index:1000;
    bottom:0.75rem;
    right:0.75rem;
    padding:0.5rem;
    opacity:0.2;
    border:1px solid #fff;
    border-radius:0.5rem;
    cursor:pointer;

    &:hover {
        opacity:0.8;
    }
` 

const Image = styled.img`
    width:50px;

    @media (max-width: 768px) {
        width:35px;
    }
`

function ScrollToTop(props) {

    function onClick(event) {
        event.preventDefault();
        var scrollOptions = {
            top: 0,
            behavior: 'smooth'
        }
        window.scrollTo(scrollOptions);
    }

    return (
        <Div onClick={onClick}>
            <Image src={arrowTop} alt="" />
        </Div>
    );
}

export default ScrollToTop;