import React from 'react';
import * as Styles from '../constants/styles';

function SliderImageComponent(props) {

    return (
        <div className="d-flex justify-content-center">
            <Styles.SliderImage src={props.image} alt={"Slider 1"} className="d-block" loading="lazy" />
        </div>
    )
}

export default SliderImageComponent;