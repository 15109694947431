import React from 'react';
import * as Styles from '../constants/styles';

import portraitImage from '../assets/images/portrait.jpg';
import susannebons from '../assets/images/susannebons.jpg';

function Vita(props) {

    return (
        <Styles.TextAlign textAlign={"center"}>
            <Styles.MarginTop className="row" remValue={2} id="vita">
                <div className="col text-center">
                    <h3>Vita Susanne Bons</h3>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    <Styles.FontSize fontSize={"1.25rem"}>
                        Bildende Künstlerin- Dozentin- Kunsttherapeutin
                    </Styles.FontSize>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <img src={portraitImage} alt={"Portrait Susanne Bons"} className="img-fluid" loading="lazy" />
                            </div>
                        </div>

                        <Styles.MarginTop className="row" remValue={1}>
                            <div className="col text-center">
                                Geboren in Ratingen bei Düsseldorf
                                <br />
                                Lebt und arbeitet in Elmpt und Ratingen
                            </div>
                        </Styles.MarginTop>
                        <Styles.MarginTop className="row" remValue={1}>
                            <div className="col">
                                <Styles.FontSize fontSize={"1.25rem"}>
                                    Künstlerischer Werdegang:
                                </Styles.FontSize>
                                <Styles.UnorderedList listStylePosition={"inside"}>
                                    <li>
                                        1995 – 2000 Studium der Malerei und Grafik, Diplom, IBKK, Bochum
                                    </li>
                                    <li>
                                        1995 – 2000 Studium Airbrush/ Design, IBKK, Bochum
                                    </li>
                                    <li>
                                        2000 – 2001 Meisterschülerin von Prof. Qi Yang
                                    </li>
                                    <li>
                                        2001 - Mitglied im BBK Düsseldorf
                                    </li>
                                    <li>
                                        2001 - Künstlerkarte der Stadt Düsseldorf
                                    </li>
                                    <li>
                                        2001 - VG Bildkunst Bonn
                                    </li>
                                    <li>
                                        2005 - Mitglied im Frauenmuseum (Kunst, Kultur, Forschung e.V.) Bonn
                                    </li>
                                    <li>
                                        2020 - 2021 Ausbildung zur Kunst- und Kreativitätstherapeutin, IEK, Berlin
                                    </li>
                                </Styles.UnorderedList>
                            </div>
                        </Styles.MarginTop>
                        <Styles.MarginTop className="row" remValue={2}>
                            <div className="col">
                                <Styles.FontSize fontSize={"1.25rem"}>
                                    Künstlerische Fachfortbildung in der Freien Kunstakademie Augsburg und der Akademie der Bildenden Künste Kolbermoor:
                                </Styles.FontSize>
                                <Styles.UnorderedList listStylePosition={"inside"}>
                                    <li>
                                        02. 2019 Gabriele Middelmann, Freie Kunstakademie Augsburg
                                    </li>
                                    <li>
                                        07. 2019 Gabriele Middelmann, Akademie d. Bild. Künste Kolbermoor
                                    </li>
                                    <li>
                                        11. 2019 Peter Tomschiczek, Akademie d. Bild. Künste Kolbermoor
                                    </li>
                                    <li>
                                        07. 2020 Gabriele Middelmann, Freie Kunstakademie Augsburg
                                    </li>
                                    <li>
                                        10. 2020 Peter Tomschiczek, Akademie d. Bild. Künste Kolbermoor
                                    </li>
                                    <li>
                                        sowie bei Ines Hildur, Christian von Grumbkow, Gabriele Musebrink, Reinhard Hochmair, Robert Süess, Jupp Linssen
                                    </li>
                                </Styles.UnorderedList>
                            </div>
                        </Styles.MarginTop>
                        <Styles.MarginTop className="row" remValue={2}>
                            <div className="col">
                                <Styles.FontSize fontSize={"1.25rem"}>Einzelausstellungen (Auswahl):</Styles.FontSize>
                                <Styles.UnorderedList listStylePosition={"inside"}>
                                    <li>
                                        1998 Roter Faden, Behr & Heil, Leipzig
                                    </li>
                                    <li>
                                        2000 Reiz des Materials, Niederrhein-Klinik, Korschenbroich
                                    </li>
                                    <li>
                                        2000 Spuren der Zeit, Taucha/ Leipzig
                                    </li>
                                    <li>
                                        2003 Offenes Atelier, Niederkrüchten
                                    </li>
                                    <li>
                                        2004 Spuren der Zeit, Artothek, Dortmund
                                    </li>
                                    <li>
                                        2009 Human Signs, Artothek, Dortmund
                                    </li>
                                </Styles.UnorderedList>
                            </div>
                        </Styles.MarginTop>
                        <Styles.MarginTop className="row" remValue={2}>
                            <div className="col">
                                <Styles.FontSize fontSize={"1.25rem"}>
                                    Gruppenausstellungen (Auswahl):
                                </Styles.FontSize>
                                <Styles.UnorderedList listStylePosition={"inside"}>
                                    <li>
                                        1997 Musee de la Poste, Amelie les Bains, Frankreich
                                    </li>
                                    <li>
                                        1999 Dat Leben is hatt Kunst im Pott, Duisburg
                                    </li>
                                    <li>
                                        2000 Städtische Galerie Hefei, Anhui, VR China
                                    </li>
                                    <li>
                                        2000 Lebende Strukturen, Kunstverein Soest, Soest
                                    </li>
                                    <li>
                                        2000 Galerie Zentrum der zeitgenössischen Kunst, Hefei, Anhui, VR China
                                    </li>
                                    <li>
                                        2002 Die Neuen, BBK, Düsseldorf
                                    </li>
                                    <li>
                                        2002 Art Niederkrüchten, Niederkrüchten
                                    </li>
                                    <li>
                                        2004 Schicht um Schicht, Kokerei Hansa, Dortmund
                                    </li>
                                    <li>
                                        2005 Strukturwandel, Altes Stadtbad, Hagen
                                    </li>
                                    <li>
                                        2007 Mit Macht zur Wahl, Frauenmuseum Bonn
                                    </li>
                                    <li>
                                        2009 Mit Macht zur Wahl, Offenburg, Schwäbisch Hall, Fellbach, Landau
                                    </li>
                                    <li>
                                        2013 Mona Lisa, Frauenmuseum Bonn
                                    </li>
                                    <li>
                                        2017 Parallel, BBK Düsseldorf in der BIG-Galerie, Dortmund
                                    </li>
                                    <li>
                                        2018 Freundinnen, Frauenmuseum Bonn
                                    </li>
                                    <li>
                                        2019 100-Jahre-Frauenwahlrecht Deutschland, Frauenmuseum Bonn
                                    </li>
                                    <li>
                                        Regelmäßige Beteiligung an den Winterausstellungen des BBK Düsseldorf
                                    </li>
                                    <li>
                                        2021 Jubiläumsausstellung, 35 Jahre BBK
                                    </li>
                                </Styles.UnorderedList>
                            </div>
                        </Styles.MarginTop>
                    </div>
                </div>
            </Styles.MarginTop>

            <Styles.MarginTop className="row" remValue={3}>
                <div className="col text-center">
                    <Styles.FontSize fontSize={"1.25rem"}>
                        -Spuren der Zeit-
                    </Styles.FontSize>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                Schon seit Beginn meiner künstlerischen Laufbahn faszinieren mich Lost Places, Abrisswände und Stätten der Industriekultur. Mit der Kamera gehe ich auf Spurensuche und schaffe mir -im weitesten Sinne- die Motive für meine Malerei. Die patinierten Oberflächen und Strukturen der verlassenen Gebäude setze ich experimentell um mit teils kunstfremden Materialien. Es ergeben sich reliefartige Flächen aus vielen Schichten mit einer starken Tiefenwirkung. Meine Arbeitsweise ist sehr intuitiv und ein ständiges Spiel mit dem Zufall.
                            </div>
                        </div>
                    </div>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <img src={susannebons} alt={"Susanne Bons"} className="img-fluid" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>
            </Styles.MarginTop>
        </Styles.TextAlign>
    )
}

export default Vita;