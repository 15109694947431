import Navbar from './components/navbar.component';
import EmailAddress from './components/emailaddress.component';
import Slider from './components/slider.component';
import Vita from './components/vita.component';
import Privacy from './components/privacy.component';
import ScrollToTop from './components/scrollToTop.component';
import GlobalFonts from './assets/fonts/fonts';
import * as Styles from './constants/styles';

import backgroundImage from './assets/images/background.jpg';
import logoImage from './assets/images/logo.jpg';
import instgramIcon from './assets/images/Instagram_Glyph_Gradient_RGB.png';
import atelier from './assets/images/atelier.jpg'

function App(props) {
  return (
    <>
      <GlobalFonts />
      <Styles.FixedBackground image={backgroundImage} />
      <ScrollToTop 
        key={"scrollToTopComponent"}
      />
      <Styles.Font>
        <Navbar
          key={"navbar"}
        />
        <div className="container-fluid">
          <Styles.MarginTop className="row" remValue={1}>
            <div className="col text-center">
              <h1>Susanne Bons - artbons.de</h1>
            </div>
          </Styles.MarginTop>
          <Styles.LogoRow className="row">
            <div className="col text-center">
              <img src={logoImage} alt={"Susanne Bons - artbons.de"} className="img-fluid" loading="lazy" />
            </div>
          </Styles.LogoRow>
        </div>
        <Styles.MarginTop className="row" remValue={1}>
          <div className="col text-center">
            Susanne Bons - Bildende Künstlerin - Dozentin – Kunsttherapeutin
          </div>
        </Styles.MarginTop>
        <Styles.MarginTop className="row" remValue={4.5}>
          <div className="col text-center">
            <div className="container">
              <div className="row">
                <div className="col text-center">
                  <h3>-In jedem Menschen schlummert ein Künstler, entdecke deine kreative Seite und erwecke den Künstler in dir-</h3>
                </div>
              </div>
            </div>
          </div>
        </Styles.MarginTop>
        <Styles.MarginTop className="row" remValue={4}>
          <div className="col text-center">
            <div className="container">
              
              <Slider
                key={"slider"}
              />

              <Styles.MarginTop className="row" remValue={4}>
                <Styles.FontSize fontSize={"1.5rem"} className="col text-center">
                  Wer hat Lust selbst kreativ zu werden? 
                </Styles.FontSize>
              </Styles.MarginTop>
              <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                  Ich veranstalte spannende Workshops rund um 
                  <Styles.UnorderedList listStylePosition={"inside"}>
                    <li>
                      Acrylmalerei
                    </li>
                    <li>
                      experimentelle Mischtechniken
                    </li>
                    <li>
                      Rost und Patina
                    </li>
                  </Styles.UnorderedList>
                </div>
              </Styles.MarginTop>
              <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                  In meinen Kursen wird jeder Kursteilnehmer individuell gefördert und in seinen Ressourcen gestärkt. 
                </div>
              </Styles.MarginTop>

              <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                  <img src={atelier} alt={"Atelier - Susanne Bons - artbons.de"} className="img-fluid" loading="lazy" />
                  <br />
                  Atelier: Papiermühlenweg 74, 40882 Ratingen
                </div>
              </Styles.MarginTop>

              <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                  Die Kurse finden in Nordrhein-Westfalen und auch online statt.
                </div>
              </Styles.MarginTop>
              <Styles.MarginTop remValue={1}>
                <Styles.FontSize fontSize={"1.5rem"} className="col text-center">
                  Interesse?
                </Styles.FontSize>
              </Styles.MarginTop>
              <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                  Sehr gern schicke ich unverbindlich Informationen zu Workshops und Terminen.
                </div>
              </Styles.MarginTop>
              <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                  Kursprogramm anfordern unter:
                  <br />
                  <EmailAddress emailAddress={"susanne@artbons.de"} />
                  <br />
                  oder unter Instagram: 
                  <br />
                  <Styles.InstagramIcon src={instgramIcon} alt={"Instagram"} />  <Styles.Link href="https://www.instagram.com/susanne_bons" target="_blank" rel="noreferrer">www.instagram.com/susanne_bons</Styles.Link>
                </div>
              </Styles.MarginTop>
              <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                  Auch Kaufanfragen für Bilder sind willkommen.
                </div>
              </Styles.MarginTop>

              <Vita
                key={"vita"}
              />

              <Styles.MarginTop className="row" remValue={4}>
                <div className="col text-center">
                  <h3>Impressum + Datenschutzerklärung</h3>
                </div>
              </Styles.MarginTop>

              <Styles.MarginTop className="row" remValue={2}>
                <div className="col text-center" id="imprint">
                  <h3>Impressum</h3>
                </div>
              </Styles.MarginTop>
              <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                  Susanne Bons
                  <br />
                  Hauptstr. 19
                  <br />
                  41372 Niederkrüchten
                  <br />
                  Tel:  <Styles.Link href="tel:+492163990847" target="_blank" rel="noreferrer">+49 (0) 2163 / 99 08 47</Styles.Link>
                  <br />
                  Fax: <Styles.VisualStyleLink>+49 (0) 2163 / 82 335</Styles.VisualStyleLink>
                  <br />
                  E-Mail: <EmailAddress emailAddress={"susanne@artbons.de"} />
                  <br />
                  <br />
                  VG Bild- Kunst Urheber Nr: 0922831
                  <br />
                  Finanzamt Viersen: 102/ 5024/ 1014
                  <br />
                  Ust-IdNr: 43 250 798 912 gemäß § 27a Umsatzsteuergesetz
                  <br />
                </div>
              </Styles.MarginTop>
              <Privacy  
                key={"privacyComponent"}
              />
              <Styles.MarginBottom remValue={2} />
            </div>
          </div>
        </Styles.MarginTop>
      </Styles.Font>
    </>
  );
}

export default App;
