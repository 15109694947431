import React from 'react';
import styled from 'styled-components';

const Nav = styled.nav`
    opacity: 0.88 !important;
    background-color:transparent !important;
` 

const MenuListItem = styled.li`
`

const MenuItem = styled.button`
    border: 0px;
    background-color: transparent;
    color:#fff !important;
`

function NavBarComponent(props) {

    function onLinkClick(event,target,callback = null) {
        event.preventDefault();
        let scrollToElement = document.getElementById(target)
        var scrollOptions = {
            top: scrollToElement.offsetTop,
            behavior: 'smooth'
        }
        
        window.scrollTo(scrollOptions);
    }

    return (
        <Nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <MenuItem className="navbar-brand cursorPointer">
                artbons.de
            </MenuItem>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <MenuListItem className="nav-item">
                        <MenuItem className="nav-link cursorPointer" onClick={(e) => onLinkClick(e,'pictures')}>Bilder</MenuItem>
                    </MenuListItem>
                    <MenuListItem className="nav-item">
                        <MenuItem className="nav-link cursorPointer" onClick={(e) => onLinkClick(e,'vita')}>Vita</MenuItem>
                    </MenuListItem>
                </ul>
                <ul className="navbar-nav mr-sm-2">
                    <MenuListItem className="nav-item">
                        <MenuItem className="nav-link cursorPointer" onClick={(e) => onLinkClick(e,'imprint')}>Impressum</MenuItem>
                    </MenuListItem>
                    <MenuListItem className="nav-item">
                        <MenuItem className="nav-link cursorPointer" onClick={(e) => onLinkClick(e,'privacy')}>Datenschutz</MenuItem>
                    </MenuListItem>
                </ul>
            </div>
        </Nav>
    );
}


export default NavBarComponent;