import React from 'react';
import EmailAddress from './emailaddress.component';
import * as Styles from '../constants/styles';


function Privacy(props) {

    return (
        <>
            <Styles.MarginTop className="row" remValue={4}>
                <div className="col text-center" id="privacy">
                    <h3>Datenschutzerklärung</h3>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    <Styles.FontSize fontSize={"1.5rem"}>
                        1. Datenschutz auf einen Blick
                    </Styles.FontSize>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    <Styles.FontSize fontSize={"1.25rem"}>
                        Allgemeine Hinweise
                    </Styles.FontSize>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    <Styles.FontSize fontSize={"1.25rem"}>
                        Datenerfassung auf unserer Website
                    </Styles.FontSize>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    <Styles.FontSize fontSize={"1.25rem"}>
                        Wer ist verantwortlich für die Datenerfassung auf dieser Website?
                    </Styles.FontSize>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    <Styles.FontSize fontSize={"1.5rem"}>
                        Wie erfassen wir Ihre Daten?
                    </Styles.FontSize>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
                    <br />
                    Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website betreten.
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    <Styles.FontSize fontSize={"1.5rem"}>
                        Wofür nutzen wir Ihre Daten?
                    </Styles.FontSize>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    <Styles.FontSize fontSize={"1.5rem"}>
                        Welche Rechte haben Sie bezüglich Ihrer Daten?
                    </Styles.FontSize>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    <Styles.FontSize fontSize={"1.5rem"}>
                        2. Allgemeine Hinweise und Pflichtinformationen
                    </Styles.FontSize>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    <Styles.FontSize fontSize={"1.25rem"}>
                        Datenschutz
                    </Styles.FontSize>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
                    <br />
                    Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
                    <br />
                    Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    <Styles.FontSize fontSize={"1.25rem"}>
                        Hinweis zur verantwortlichen Stelle
                    </Styles.FontSize>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    <div>
                        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
                    </div>
                    <div>
                        Susanne Bons
                    </div>
                    <Styles.FontWeight fontWeight={"bold"}>
                        artbons
                    </Styles.FontWeight>
                    <div>
                        Hauptstr. 19
                    </div>
                    <div>
                        41372 Niederkrüchten
                    </div>
                    <div>
                        Deutschland
                    </div>
                    <Styles.MarginTop remValue={1}>
                        VG Bild- Kunst Urheber Nr: 0922831
                    </Styles.MarginTop>
                    <Styles.MarginTop remValue={1}>
                        Finanzamt Viersen 102/5024/1014, Steuer Nr: 43 250 798 912
                    </Styles.MarginTop>
                    <Styles.MarginTop remValue={1}>
                        Telefon: <Styles.Link href="tel:+492163990847" target="_blank" rel="noreferrer">+49 (0) 2163 / 99 08 47</Styles.Link>
                        <br />
                        E-Mail: <EmailAddress emailAddress={"susanne@artbons.de"} />
                    </Styles.MarginTop>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    <Styles.FontSize fontSize={"1.25rem"}>
                        Widerruf Ihrer Einwilligung zur Datenverarbeitung
                    </Styles.FontSize>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    <Styles.FontSize fontSize={"1.25rem"}>
                        Beschwerderecht bei der zuständigen Aufsichtsbehörde
                    </Styles.FontSize>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem Link entnommen werden: <Styles.Link href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_self">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</Styles.Link>.
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    <Styles.FontSize fontSize={"1.25rem"}>
                        Recht auf Datenübertragbarkeit
                    </Styles.FontSize>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    <Styles.FontSize fontSize={"1.25rem"}>
                        SSL- bzw. TLS-Verschlüsselung
                    </Styles.FontSize>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    <Styles.FontSize fontSize={"1.25rem"}>
                        Auskunft, Sperrung, Löschung
                    </Styles.FontSize>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    <Styles.FontSize fontSize={"1.25rem"}>
                        Widerspruch gegen Werbe-Mails
                    </Styles.FontSize>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
                </div>
            </Styles.MarginTop>


            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    <Styles.FontSize fontSize={"1.5rem"}>
                        3. Datenerfassung auf unserer Website
                    </Styles.FontSize>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    <Styles.FontSize fontSize={"1.25rem"}>
                        Cookies
                    </Styles.FontSize>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
                    <br />
                    Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
                    <br />
                    Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
                    <br />
                    Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    <Styles.FontSize fontSize={"1.25rem"}>
                        Server-Log-Dateien
                    </Styles.FontSize>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:
                    <br />
                    <Styles.UnorderedList listStylePosition={"inside"}>
                        <li>
                            Browsertyp und Browserversion
                        </li>
                        <li>
                            Verwendetes Betriebssystem
                        </li>
                        <li>
                            Referrer URL
                        </li>
                        <li>
                            Hostname des zugreifenden Rechners
                        </li>
                        <li>
                            Uhrzeit der Serveranfrage
                        </li>
                        <li>
                            IP-Adresse
                        </li>
                    </Styles.UnorderedList>
                    <br />
                    Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.
                    <br />
                    Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
                    <br />
                    Zusätzliche Erklärung im Datenschutz: (bitte sprechen!)
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    <Styles.FontSize fontSize={"1.25rem"}>
                        Datenschutzerklärung für die Nutzung von Instagram
                    </Styles.FontSize>
                </div>
            </Styles.MarginTop>
            <Styles.MarginTop className="row" remValue={1}>
                <div className="col text-center">
                    Auf unseren Seiten sind Funktionen des Dienstes Instagram eingebunden. Diese Funktionen werden angeboten durch die Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA integriert. Wenn Sie in Ihrem Instagram - Account eingeloggt sind können Sie durch Anklicken des Instagram - Buttons die Inhalte unserer Seiten mit Ihrem Instagram - Profil verlinken. Dadurch kann Instagram den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Instagram erhalten. Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Instagram: <Styles.Link href="http://instagram.com/about/legal/privacy" target="_self">http://instagram.com/about/legal/privacy</Styles.Link>
                </div>
            </Styles.MarginTop>
        </>
    )
}

export default Privacy;