import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import SliderImage from './sliderImage.component';

import sliderImage1 from '../assets/images/slider/1.jpg';
import sliderImage2 from '../assets/images/slider/2.jpg';
import sliderImage3 from '../assets/images/slider/3.jpg';
import sliderImage4 from '../assets/images/slider/4.jpg';
import sliderImage5 from '../assets/images/slider/5.jpg';
import sliderImage6 from '../assets/images/slider/6.jpg';
import sliderImage7 from '../assets/images/slider/7.jpg';
import sliderImage8 from '../assets/images/slider/8.jpg';
import sliderImage9 from '../assets/images/slider/9.jpg';
import sliderImage10 from '../assets/images/slider/10.jpg';
import sliderImage11 from '../assets/images/slider/11.jpg';
import sliderImage12 from '../assets/images/slider/12.jpg';
import sliderImage13 from '../assets/images/slider/13.jpg';
import sliderImage14 from '../assets/images/slider/14.jpg';
import sliderImage15 from '../assets/images/slider/15.jpg';
import sliderImage16 from '../assets/images/slider/16.jpg';
import sliderImage17 from '../assets/images/slider/17.jpg';
import sliderImage18 from '../assets/images/slider/18.jpg';
import sliderImage19 from '../assets/images/slider/19.jpg';
import sliderImage20 from '../assets/images/slider/20.jpg';
import sliderImage21 from '../assets/images/slider/21.jpg';
import sliderImage22 from '../assets/images/slider/22.jpg';
import sliderImage23 from '../assets/images/slider/23.jpg';
import sliderImage24 from '../assets/images/slider/24.jpg';
import sliderImage25 from '../assets/images/slider/25.jpg';
import sliderImage26 from '../assets/images/slider/26.jpg';
import sliderImage27 from '../assets/images/slider/27.jpg';
import sliderImage28 from '../assets/images/slider/28.jpg';
import sliderImage29 from '../assets/images/slider/29.jpg';
import sliderImage30 from '../assets/images/slider/30.jpg';
import sliderImage31 from '../assets/images/slider/31.jpg';
import sliderImage32 from '../assets/images/slider/32.jpg';
import sliderImage33 from '../assets/images/slider/33.jpg';
import sliderImage34 from '../assets/images/slider/34.jpg';
import sliderImage35 from '../assets/images/slider/35.jpg';
import sliderImage36 from '../assets/images/slider/36.jpg';
import sliderImage37 from '../assets/images/slider/37.jpg';
import sliderImage38 from '../assets/images/slider/38.jpg';
import sliderImage39 from '../assets/images/slider/39.jpg';
const sliderImages = [
    sliderImage1,
    sliderImage2,
    sliderImage3,
    sliderImage4,
    sliderImage5,
    sliderImage6,
    sliderImage7,
    sliderImage8,
    sliderImage9,
    sliderImage10,
    sliderImage11,
    sliderImage12,
    sliderImage13,
    sliderImage14,
    sliderImage15,
    sliderImage16,
    sliderImage17,
    sliderImage18,
    sliderImage19,
    sliderImage20,
    sliderImage21,
    sliderImage22,
    sliderImage23,
    sliderImage24,
    sliderImage25,
    sliderImage26,
    sliderImage27,
    sliderImage28,
    sliderImage29,
    sliderImage30,
    sliderImage31,
    sliderImage32,
    sliderImage33,
    sliderImage34,
    sliderImage35,
    sliderImage36,
    sliderImage37,
    sliderImage38,
    sliderImage39
]
const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    pauseOnFocus: true,
    //className: 'sliderButtons',
    autoplay: true,
    autoplaySpeed: 4000,
};

function SliderComponent(props) {

    return (
        <>
            <div className="row" id="pictures">
                <div className="col">
                    <div>
                    <Slider {...sliderSettings}>
                        {
                            sliderImages.map((image,index) => {
                                return (
                                    <SliderImage 
                                        key={"image" + index}
                                        image={image}
                                    /> 
                                )
                            })
                        }
                    </Slider>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SliderComponent;